@import './src/styles/variables';

@mixin input {
  border: none;
  border-bottom: 2px solid $color-text-light-grey;
  width: 100%;
  font-size: $text-medium-sm;
  color: $main-text;

  &::placeholder {
    font-size: $text-standard;
    opacity: 0.5;
    color: $color-background-violate;
  }

  &:focus,
  &:active {
    outline: none;
    border-bottom: 2px solid $color-background-violate;
  }
}

.ui-input {
  position: relative;

  input {
    @include input;
  }

  &__hint-wrapper {
    padding: 5px 0 0 0;
    font-size: $text-standard;
  }

  .rc-input-suffix {
    position: absolute;
    right: 0;
    top: 50%;
    background-color: $color-background-white;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: $color-text-grey3;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
  }

  &:focus-within {
    label {
      color: $color-text-violet;
    }
  }
}


.ui-checkbox {
  cursor: pointer;
  
  &--label {
    display: flex;
    align-items: center;
  }

  &--box  {
    width: 22px;
    height: 22px;
    border: 2px solid $color-background-grey;
    border-radius: 4px;
    margin-right: 8px;

    svg {
      position: relative;
      top: -1px;
      transform: scale(1.1);
    }
  }
}