@import "../../../../styles/variables";

.dating-service {

  &__container {
  }

  &__info {
    @include info-block("right");
  }

  &__header {
    @include header-text("right");
  }

  &__description {
    @include description-text("right");
  }
}

@media #{$tablet} {
  .dating-service {

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      order: -1;
      padding-top: 40px !important;
    }

    &__header {
    }

    &__description {
    }
  }
}
