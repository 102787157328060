@import '../../styles/variables.scss';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &__item {
    display: flex;
    align-items: center;
    &-last {
      pointer-events: none;
      a {
        font-weight: 300;
        color: $color-text-violet;
      }
    }
    &-disabled {
      pointer-events: none;
    }
  }

  &__separator {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 100;
    font-family: KohinoorBangla;
    color: #919EAB;
  }

  &__text {
    margin: 0;
    color: #4a4a4a;
    font-weight: 600;
    line-height: 23px;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #4A4A4A;
  }
}
