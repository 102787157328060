@import '../../styles/variables';

.video-presentation {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0005;
  z-index: 9;
  color: $main-text;

  &--video-popup {
    position: relative;
    width: 70vw;
    height: 39.4vw;
    background: #000;

    span {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 9;
      cursor: pointer;
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    iframe {
      // width: 100%;
      // height: 100%;
    }
  }
}

@media #{$tablet} {
  .video-presentation {
    &--video-popup {
      width: 91vw;
      height: 51.4vw;
    }
  }
}
