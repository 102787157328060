@import '../../../../styles/variables';

.subscription-section {
  color: $main-text;
  overflow: hidden;
  .container {
    position: relative;
    padding-top: 100px;
    padding-bottom: 331px;
    flex-direction: column;
    max-width: 924px;
  }

  &--bg {
    position: absolute;
    bottom: 0px;
    left: -80px;
  }

  &__header {
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    padding-bottom: 20px;
  }

  &__description {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    max-width: 622px;
  }

  &__devices {
    padding: 0 125px 30px 125px;
    min-height: 157px;
    text-align: center;
  }

  &--img {
    position: absolute;
    bottom: 34px;
    right: 0;
    z-index: 1;
  }
}

@media #{$tablet} {
  .subscription-section {
    &__info {
      max-width: 100% !important;
    }

    &__header {
    }

    &__description {
      @include description-text('left');
      padding: 20px 0 0;
    }

    &__promo-image {
      justify-content: center;
      padding-bottom: 40px;
      img {
        position: static;
        width: 90%;
      }
    }

    &__devices {
      padding: 15px 20px;
    }

    &__devices-description {
      padding: 25px 10px;
    }
  }
}
