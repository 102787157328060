@import "../../../../styles/variables";

.events {

  &__container {
  }

  &__info {
    @include info-block("right");
  }

  &__header {
    @include header-text("right");

  }
  &__description {
    @include description-text("right");
  }

  &__chunk {
    background-color: $color-background-white;
  }

  &__button {
    @include button;
    margin-left: auto;
    margin-top: 37px;
    cursor: pointer;
  }
}

@media #{$tablet} {
  .events {
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      order: -1;
    }

    &__header {
    }

    &__description {
    }

    &__button {
      margin: 70px auto 0;
    }
  }
}

