.user-profile {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  &__name {
    margin: 5px 0;
  }

  &__balance {

  }
}
