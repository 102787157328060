@import '../../styles/variables.scss';

.askForCookie {
  position: fixed;
  bottom: -1000px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  z-index: 99999;
  -webkit-box-shadow: -2px -3px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -2px -3px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: -2px -3px 8px 0px rgba(34, 60, 80, 0.2);
  transition: all .5s;

  &--opened {
    bottom: 0;
  }

  &--btn {
    max-width: 200px;
    button {
      padding: 10px;
      font-size: 24px;
    }
  }
}
