@import '../../../styles/variables.scss';

.uiBtn {
  background-color: $color-text-violet;
  font-size: 17px;
  line-height: 26px;
  color: #fff;
  border-radius: 10px;
  border: 1px solid $color-text-violet;
  width: 100%;

  button {
    width: 100%;
    height: 100%;
    border: none;
    padding: 16px 0;
    margin: 0;
    display: block;
    text-transform: uppercase;
    background-color: transparent;
    color: inherit;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 3px 7px rgba(175, 144, 255, 0.5);
  }

  &:hover {
    background-color: #fff;
    color: $color-text-violet;
  }

  &--o {
    background-color: #fff;
    color: $color-text-violet;

    &:hover {
      background-color: $color-text-violet;
      color: #fff;
    }
  }
}

@media #{$mobile} {
  .uiBtn {
    button {
      font-size: 17px;
      line-height: 26px;
    }
  }
}
