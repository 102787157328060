@import "../../../styles/variables";

.sources {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    @include header-text
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__example {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }

  &__description, &__links {
    display: flex;
    font-size: $text-standard;
    margin-top: 5px;
    max-width: 284px;
  }

  &__links {
    justify-content: center;
    a {
      color: $color-background-violate;
    }

  }

  &__text {
    text-align: justify;
    color: $color-text-dark-grey;
  }
}

@media #{$tablet} {
  .sources {
  }
}

@media #{$mobile} {
  .sources {
  }
}
