@import "../../../../styles/variables";

.internal-currency {

  &__container {
  }

  &__info {
    @include info-block("right");
  }

  &__header {
    @include header-text("right");

  }

  &__description {
    @include description-text("right");
    max-width: 80%;
    margin-left: auto;
  }

  &__phone {
    position: relative;
  }

  &__phone-account {
    position: absolute;
    left: 50%;
    bottom: 25px;
  }

  &__chunk {
    background-color: $color-background-white;
  }
}

@media #{$tablet} {
  .internal-currency {
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      order: -1;
    }

    &__header {
    }

    &__description {
      max-width: 100%;
      margin-left: 0;
    }

    &__phone-account {
      left: 10%;
      width: 80%;
      bottom: -5px;
    }
  }
}

@media #{$sm-mobile} {
  .internal-currency {
    &__phone-account {
      width: 80% !important;
    }
  }
}



