@font-face {
  font-family: 'KohinoorBangla';
  src: url('../assets/fonts/kohinoorbangla-bold-04-webfont.eot');
  src: url('../assets/fonts/kohinoorbangla-bold-04-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/kohinoorbangla-bold-04-webfont.woff2') format('woff2'),
  url('../assets/fonts/kohinoorbangla-bold-04-webfont.woff') format('woff'),
  url('../assets/fonts/kohinoorbangla-bold-04-webfont.ttf') format('truetype'),
  url('../assets/fonts/kohinoorbangla-bold-04-webfont.svg#KohinoorBangla-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'KohinoorBangla';
  src: url('../assets/fonts/kohinoorbangla-semibold-02-webfont.eot');
  src: url('../assets/fonts/kohinoorbangla-semibold-02-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/kohinoorbangla-semibold-02-webfont.woff2') format('woff2'),
  url('../assets/fonts/kohinoorbangla-semibold-02-webfont.woff') format('woff'),
  url('../assets/fonts/kohinoorbangla-semibold-02-webfont.ttf') format('truetype'),
  url('../assets/fonts/kohinoorbangla-semibold-02-webfont.svg#KohinoorBangla-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'KohinoorBangla';
  src: url('../assets/fonts/kohinoorbangla-regular-01-webfont.eot');
  src: url('../assets/fonts/kohinoorbangla-regular-01-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/kohinoorbangla-regular-01-webfont.woff2') format('woff2'),
  url('../assets/fonts/kohinoorbangla-regular-01-webfont.woff') format('woff'),
  url('../assets/fonts/kohinoorbangla-regular-01-webfont.ttf') format('truetype'),
  url('../assets/fonts/kohinoorbangla-regular-01-webfont.svg#KohinoorBangla-Regular') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'KohinoorBangla';
  src: url('../assets/fonts/kohinoorbangla-light-05-webfont.eot');
  src: url('../assets/fonts/kohinoorbangla-light-05-webfont.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/kohinoorbangla-light-05-webfont.woff2') format('woff2'),
  url('../assets/fonts/kohinoorbangla-light-05-webfont.woff') format('woff'),
  url('../assets/fonts/kohinoorbangla-light-05-webfont.ttf') format('truetype'),
  url('../assets/fonts/kohinoorbangla-light-05-webfont.svg#KohinoorBangla-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
