@import "../../styles/variables";

.profile-page {
  display: flex;
  width: 100%;

  &__menu-container {
    width: 300px;
    padding: 0 20px;
    border-right: 1px solid $color-background-grey;
    background-color: $color-background-white;
  }

  &__nested-pages {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
  }
}
@media #{$tablet} {
  .profile-page {
    position: relative;
    justify-content: center;

    &__menu-container {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: center;
      border: 1px solid $color-background-grey;
    }
    &__nested-pages {
      padding-top: 100px;
    }
  }
}
