@import './styles/variables.scss';

* {
  box-sizing: border-box;
}

.twr *{
  all: revert;
}

body {
  font-family: 'Poppins', sans-serif;
}

input,
select,
textarea,
button {
  font-family: inherit;
}

body,
p,
ul,
li {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;

  a:visited {
    text-decoration: none;
  }
}

body {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: $container-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.routes-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-width;
}

.login-container {
  flex: 1 0 auto;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.profile-container {
  flex: 1 0 auto;
  display: flex;
}

.section-block {
  display: flex;
  flex: 1 0 auto;
  padding: 0 50px;
}

.phone {
  margin-top: 80px;
  z-index: 1;
}

.space-block {
  height: 150px !important;
}

.negative {
  margin-top: -85px;
}

.documents {
  padding: 20px 0;
  color: $color-text-dark-grey;
  overflow-wrap: break-word;

  p {
    margin: 5px 0;
    text-align: justify;
    line-height: 1.5em;
  }

  ul {
    margin-left: 50px;
    line-height: 1.5em;
  }

  li {
    margin: 5px 0;
    list-style: circle;
  }

  span {
    line-height: 1.5em;
    text-align: justify;
  }
}

.error-text {
  color: $color-text-red;
}

.success-text {
  color: $color-text-green;
}

.warning-text {
  color: $color-text-orange;
}

@media #{$tablet} {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .routes-container {
    margin: 0;
  }

  .section-block {
    padding: 0 20px;
  }

  .phone {
    margin: 0;
    padding-top: 0;
  }

  .space-block {
    height: 86px !important;
  }

  .negative {
    margin-top: -85px;
  }

  .documents {
    padding: 20px;
  }

  .profile-container {
    display: block;
  }
}

@media #{$sm-mobile} {
  .phone {
    img {
      width: 100%;
    }
  }
}
