.prepared-sticker {
  img {
    width: 100%;
    object-fit: contain;
  }
}

.adding-stickers-form {
  &__add-btn {
    path {
      &:hover {
        fill: #AF90FF;
        &+path {
          fill: white;
        }
      }
    }
  }
}
