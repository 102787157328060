@import '../../styles/variables';

.login-menu {
  min-width: 100px;
  position: relative;
  display: flex;

  &__button {
    position: relative;
    border: none;
    cursor: pointer;
    background-color: initial;
    font-size: $text-standard-mobile;
    line-height: 27px;
    font-weight: 400;
    color: $color-text-grey;

    &:hover {
      color: $color-text-violet;
    }
  }

  &__list {
    position: absolute;
    right: 20px;
    bottom: -90px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 150px;
    border-radius: 5px;
    border: 1px solid $color-background-grey;
    background-color: $color-background-white;
    padding: 10px 20px;
  }

  &__item,
  li {
    padding: 5px 0;

    &__link {
      display: block;
    }

    &__logout-button {
      margin-left: auto;
      border: none;
      cursor: pointer;
      padding: 5px;
      background-color: $color-background-violate;
      font-size: $text-standard;
      color: $color-text-white;
      border-radius: 2px;
    }
  }
}

@media #{$tablet} {
  .login-menu {
    justify-content: center;

    &__button {
      font-size: $text-medium-mobile;
    }

    &__list {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
