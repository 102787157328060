$mobile-size: 720px;
$small-mobile-size: 374px;
$tablet-size: 1023px;

$container-width: 1388px;


$container-lg-width: 1024px;

$mobile: "screen and (max-width: 720px)";
$tablet: "screen and (max-width: 1024px)";
$sm-mobile: "screen and (max-width: 374px)";


$media-breakpoints: (
        xs: $sm-mobile,
        sm: $mobile,
        md: $tablet,
);

$section-height: 610px;

//font sizes desktop

$text-small: 12px;
$text-standard: 14px;
$text-medium-sm: 18px;
$text-medium: 20px;
$text-big-sm: 40px;
$text-big: 60px;

//font sizes mobile

$text-small-mobile: 12px;
$text-standard-mobile: 14px;
$text-medium-sm-mobile: 18px;
$text-medium-mobile: 20px;
$text-big-md-mobile: 30px;
$text-big-sm-mobile: 40px;
$text-big-mobile: 37px;
