@import '../../styles/variables';

.login-page {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  background: #0005;
  z-index: 9;
  color: $main-text;

  &--logo {
    overflow: hidden;
    width: 120px;
    transform: scale(0.75) translateX(-20px);
  }

  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    margin: 0;
    margin-top: 10px;
  }

  p {
    color: $color-text-grey3;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 30px;
  }

  &--bg {
    position: absolute;
    pointer-events: none;
    left: -79px;
    top: -140px;
    z-index: -1;

    &:nth-child(2) {
      z-index: 0;
      top: 140px;
      left: -100px;
    }
    &.mob {
      display: none;
    }
  }

  &__form-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 475px;
    min-height: 572px;
    //height: 395px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 50px 30px;
    background: #fff;
    margin-left: 100px;

    .login-form {
      position: relative;
      &__item {
        margin-bottom: 25px;
      }

      &__link {
        display: flex;
        justify-content: center;
        font-weight: 300;
        font-size: 13px;
        line-height: 20px;
        margin-top: 40px;

        a,
        a:visited {
          color: $main-text !important;
        }
      }

      &__error {
        word-break: break-all;
        font-size: $text-small;
        color: $color-background-red;
      }

      &--checkbox {
        margin-bottom: 20px;
      }
    }
  }
}

@media #{$tablet} {
  .login-form {
    &--label-checkbox {
      font-size: 13px;
      line-height: 20px;
    }

    &__item {
      margin-bottom: 15px !important;
      button {
        padding: 15px 0;
      }
    }

    &--checkbox {
      margin-top: 29px;
    }
  }

  .login-page {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 20px;
    }

    &__form-container {
      margin: 0 auto;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 19px;
      min-height: auto;
    }

    &--bg {
      display: none;
      &.mob {
        display: block;
        left: 39px;
        top: -73px;

        &:nth-child(2) {
          z-index: 1;
          top: -49px;
          left: -19px;
        }
      }
    }
  }
  .login-page__form-container .login-form__link {
    margin-top: 35px;
  }
}
