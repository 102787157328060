@import "../../../../styles/variables";

.wish-list {

  &__container {
    background-color: $color-background-gold;
  }

  &__info {
    @include info-block("left");
  }

  &__header {
    @include header-text('left');
    color: $color-text-white;
  }

  &__description {
    @include description-text('left');
    color: $color-text-white;
    padding-right: 50px;
  }

  &__phone {
    position: relative;
  }

  &__lens {
    position: absolute;
    right: 50%;
    top: 80px;
  }

  &__chunk {
    background-color: $color-background-gold;
  }

  &__footer {
    height: 150px;
    background-color: $color-background-white;
  }
}

@media #{$tablet} {
  .wish-list {

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      order: -1;
    }

    &__header {
    }

    &__description {
      padding-right: 0;
    }

    &__lens {
      top: auto;
      bottom: 50px;
      right: 30px;
    }
  }
}
