@import '../../../styles/variables.scss';

.blogPage {
  flex-direction: column;
  font-family: 'Kohinoor Bangla', 'Poppins';
  &:first-child {
    background: $color-background-yellow-2;
  }

  &--container.container {
    max-width: 924px;

    .list {
      padding-top: 40px;
      padding-bottom: 90px;
    }
  }

  &--container.list {
    padding-top: 40px;
    padding-bottom: 90px;
  }

  &--main {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 110px;
    padding-bottom: 110px;
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  &--header {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 1px;
    width: 345px;
    color: $color-text-dark-grey;
  }

  &--list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;

    li {
      width: 294px;
      color: $color-text-dark-grey;
      img {
        width: 100%;
        margin-bottom: 20px;
      }

      div {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
      }

      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 1px;
      }
    }
  }
}

@media #{$tablet} {
  .blogPage {
    flex-direction: column;
    font-family: 'Kohinoor Bangla', 'Poppins';
    &:first-child {
      background: $color-background-yellow-2;
    }

    &--container.list {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &--main {
      flex-direction: column;
      padding-bottom: 0;
      padding-top: 0;
      img {
        position: static;
        width: 100%;
      }
    }

    &--header {
      font-size: 9vw;
      line-height: 13vw;
      width: 88%;
      align-self: flex-start;
    }

    &--list {
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 40px;

      li {
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}
