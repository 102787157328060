@import '../../../styles/variables.scss';



.functionsSection {
  padding-top: 59px;
  &--title {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: $main-text;
    margin-bottom: 60px;
  }
}

.funcsList {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

@media #{$tablet} {
  .funcsList {
    grid-template-columns: 100%;
  }
  .functionsSection {
    padding-top: 59px;
    &--title {
      font-size: 18px;
      line-height: 27px;
      margin-bottom: 20px;
    }
  }
}
