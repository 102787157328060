@import '../../../../styles/variables';

.currency-section {
  flex-direction: column;

  &__header-container {
    padding-top: 60px;
    padding-bottom: 60px;
    background: $color-background-yellow;
    margin-bottom: 50px;
  }

  &__header {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: $main-text;
    margin-bottom: 20px;
  }

  &__description {
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color: $main-text;
    max-width: 755px;
    margin-left: auto;
    margin-right: auto;
  }

  &__reward,
  &__notification,
  &__sources-link {
    @include secondary-description-text;
    color: $color-text-dark-grey;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  &__image-container {
    margin-top: 22px;
    display: flex;
    justify-content: center;
  }

  &__notification {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    padding-top: 103px;
    padding-bottom: 20px;
  }

  &__icons-container {
    display: flex;
    justify-content: space-between;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
  }

  &__icon-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
    }
  }

  &__hint {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    width: 220px;
    padding-top: 20px;
  }

  &__reward {
    max-width: 932px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    padding-bottom: 80px;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: $main-text;
  }

  &__chunk {
    background-color: $color-background-white;
  }
}

@media #{$tablet} {
  .currency-section {
    &__header-container {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__header {
      margin: 0;
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
    }

    &__description {
      font-size: 17px;
      line-height: 26px;
      padding: 20px 0 0;
    }

    &__image-container {
      margin-left: -20px;
      margin-right: -20px;
      img {
        width: 100%;
      }
    }

    &__notification {
      font-size: 18px;
      line-height: 27px;
      padding: 20px;
    }

    &__icons-container {
      flex-direction: column;
      align-items: center;
    }

    &__icon-group {
      width: 30%;
      margin-bottom: 20px;
    }

    &__hint {
      padding: 20px 10px;
    }

    &__reward {
      padding: 5px 20px 0;
      margin-bottom: 40px;
    }
  }
}

@media #{$tablet} {
  .currency-section {
    &__icon-group {
      width: 50%;
    }
  }
}
