@import '../../styles/variables';

.adding-stickers {
  align-self: flex-start;
}

.adding-stickers-form {
  width: 100%;
  // background: #0001;
  &__fieldset {
    // display: flex;
    width: 100%;
    margin-bottom: 10px;
  }

  &__input {
    min-width: 300px;
    input {
      color: #4a4a4a;
      font-family: 'Kohinoor Bangla';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 26px;
      padding-left: 40px;
      padding-right: 20px;

      &:focus {
        border-color: #af90ff;
      }
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    .rc-input-prefix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .rc-input-suffix {
      transform: translateY(-50%);
    }
  }

  &__suffix {
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    color: #919eab;
  }

  &__prefix {
    color: #000;
  }
}

.adding-stickers-form {
  // &__button {
  //   margin-left: auto;
  //   button {
  //     font-weight: 400;
  //     border-radius: 8px;
  //     font-size: 17px;
  //     line-height: 20px;
  //     letter-spacing: 1px;
  //     text-transform: uppercase;
  //     padding: 15px 30px;
  //     width: auto;
  //     height: auto;
  //   }
  // }

  &__pack-label {
    font-family: 'Kohinoor Bangla';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #af90ff;
    letter-spacing: 1px;
    cursor: pointer;
  }

  &__add-btn {
    position: relative;
    width: 98px;
    height: 98px;
    display: flex;
    margin: 5px;
    cursor: pointer;
  }

  &--sticker {
    &-cape {
      cursor: pointer;
      position: absolute;
      inset: 0;
      background: #0004;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      img {
        height: 40%;
      }
    }

    &:hover &-cape {
      opacity: 1;
    }
  }

  &--emoji {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-size: 28px;
    right: -15px;
    top: -15px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
}

.sticker-monitor {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
  max-width: 550px;
  max-height: 550px;
  overflow: auto;
}

@media #{$tablet} {
  .adding-stickers-form {
    &__fieldset {
      flex-wrap: wrap;
    }

    &__input, &__input:not(:last-child) {
      width: 100%;
      margin: 0;
    }
  }
}
