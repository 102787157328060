@import "../../../../styles/variables";

.security-section {
  padding-bottom: 50px;

  &__container {
    flex-direction: row-reverse;
    min-height: 550px;

  }

  &__info {
    @include info-block("right");
    padding-top: 0;
    display: flex;
    flex-direction: column;
  }

  &__header {
    @include header-text("right");
    margin-top: auto;
  }

  &__description {
    @include description-text("right");
    padding: 10px 0 0 80px;
  }

  &__security-image {
    z-index: -1;
    display: flex;
    align-items: center;
    img {
      position: relative;
      left: 120px;
      top: -39px;
    }
  }
}


@media #{$tablet} {
  .security-section {
    &__container {
      flex-direction: column;
      min-height: 632px;
    }

    &__header {
    }

    &__description {
      padding: 25px 20px;
    }

    &__security-image {
      justify-content: center;
      img {
        position: static;
        width: 90%;
      }
    }
  }
}
