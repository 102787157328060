@import "../../../styles/variables";

.burger-menu {
  display: none;
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

@media #{$tablet} {
  .burger-menu {
    display: flex;
    align-items: center;
  }
}
