@import '../../styles/variables';

.footer {
  background-color: #fff;
  color: $main-text;

  &.notProductRoute {
    background-color: $color-background-violate;
    color: #fff;

    a,
    .footer__navigation-item a,
    .footer__navigation-title {
      color: #fff;
    }

    .footer__select {
      select {
        border-color: #fff;
        color: #fff;
        background-image: url('../../assets/svg/icon_arrow_white.svg');
      }
    }

    .footer__social-networks {
      a {
        background: $color-background-violate;
      }
    }
  }

  .container {
    display: flex;
    padding: 50px 10px 30px;
  }

  &--logo {
    margin-bottom: 14px;
  }

  &--sub {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: $color-text-grey;
    // letter-spacing: 0.1px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: #fff;

    img {
      margin: 0px 4px;
    }
  }

  &__navigation-item {
    display: flex;
    flex-direction: column;

    &:nth-child(1) {
      width: 316px;
    }
    &:nth-child(2) {
      width: 274px;
    }
    &:nth-child(4) {
      margin-left: auto;
    }

    li {
      padding: 4px 0;
      a {
        color: $main-text;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        &:visited {
          color: $main-text;
        }
        &:hover {
          color: $color-background-violate;
        }
      }
    }

    li:hover {
      cursor: pointer;
    }

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
    }
  }

  &__navigation-title {
    padding: 5px 0;
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    color: $color-text-grey2;
    margin-bottom: 16px;
  }

  &__select {
    margin-bottom: 22px;
    select {
      font-family: 'Poppins', sans-serif;
      border: 1px solid #919eab;
      border-radius: 4px;
      width: 199px;
      height: 44px;
      padding-left: 20px;
      // color: $color-text-white;

      background: url('../../assets/svg/icon_arrow.svg') no-repeat right;
      -webkit-appearance: none;
      background-position-x: 161px;
    }

    select:focus {
      outline: none;
    }
  }

  &__social-networks {
    margin-top: 10px;
    display: flex;

    a {
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 100%;
      background: #fff;
      margin-right: 10px;
    }

    img {
      margin-right: 11px;
    }
  }

  &__copyright {
    display: flex;
    margin-top: auto;
    flex-direction: column;
    // color: $color-text-white;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;

    a {
      // color: $color-text-white;
      text-decoration: underline;
    }
  }
  &--sub {
    &.mob {
      display: none;
    }
  }
}

@media #{$mobile} {
  .footer {
    &--sub {
      display: none;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      img {
        display: inline-block;
      }

      &.mob {
        display: block;
      }
    }
    .container {
      flex-direction: column;
      padding-top: 45px;
      padding-right: 20px;
      padding-left: 20px;
    }

    &__navigation-item {
      margin-bottom: 20px;
      &:nth-child(4) {
        margin-left: 0;
      }
    }

    &__navigation-title {
      margin-bottom: 10px;
      opacity: 0.7;
    }

    &__select {
      select {
        width: 167px;
        height: 34px;
        background-position-x: 145px;
      }
    }

    &__social-networks {
      margin: 16px 0 0;
    }

    &__copyright {
      margin-top: 10px;
      span {
        text-align: center;
      }
      br {
        display: none;
      }
    }
  }
}

@media #{$sm-mobile} {
  .footer {
    &__select {
      select {
        width: 147px;
        height: 30px;
        background-position-x: 125px;
      }
    }
  }
}
