@import "../../styles/variables";

.reset-password-page {
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;

  &__redirect {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100px;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    width: 350px;
    min-height: 250px;
    box-shadow: 0 0 16px rgba(175, 144, 255, 0.24);
    border-radius: 16px;
    padding: 30px 37px;

    .reset-form {

      &__item {
        margin: 0 0 25px 0;
      }

      &__link {
        display: flex;
        justify-content: center;
      }

      &__hint {
        color: $color-background-violate;
      }

      &__error {
        color: $color-background-red;
      }

      &__error, &__hint {
        word-break: break-all;
        font-size: $text-small;
      }

      &__repeat-button {
        border: none;
        outline: none;
        background-color: transparent;
        text-decoration: underline;
        cursor: pointer;
        font-size: $text-small;
        padding: 0;
      }
    }
  }
}


@media #{$tablet} {
  .reset-password-page {
    &__form-container {
      margin: 0 auto;
    }
  }
}
