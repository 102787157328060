@import "../../../../styles/variables";

.subscription-system {

  &__container {
    background-color: $color-background-blue;
  }

  &__info {
    @include info-block("left");
  }

  &__header {
    @include header-text('left');
    color: $color-text-white;
  }

  &__description {
    @include description-text('left');
    color: $color-text-white;
  }

  &__chunk {
    background-color: $color-background-blue;
  }

  &__footer {
    height: 150px;
    background-color: $color-background-white;
  }
}

@media #{$tablet} {
  .subscription-system {

    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      order: -1;
    }

    &__header {
    }

    &__description {
    }
  }
}
