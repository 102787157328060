@import '../../../styles/variables.scss';

.functionsDetailedSection {
  padding-top: 40px;
  .container {
    padding-bottom: 89px;
  }

  &--breadcrumbs {
    margin-bottom: 40px;
  }

  &--imageBlock {
    position: relative;
    height: 480px;
    width: 100%;
    background: #0001;
    margin-bottom: 40px;
  }

  &--mainImg {
    width: 100%;
    object-fit: cover;
    object-position: left;
    height: 100%;
  }

  &--phoneImg {
    position: absolute;
    top: 80px;
    right: 30px;
    width: 327px;
  }

  &--phoneImg-mob {
    display: none;
  }

  &--descriptinBlock {
    h2 {
      margin-top: 10px;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      font-size: 24px;
      line-height: 48px;
      color: $main-text;
      text-transform: uppercase;
    }

    p {
      color: #b2b2b2;
      max-width: 900px;
      font-weight: 500;
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 20px;
      width: 67%;
    }
  }
}

@media #{$tablet} {
  .functionsDetailedSection {
    &--imageBlock {
      margin-left: -20px;
      margin-right: -20px;
      width: auto;
      height: 35vw;
    }

    &--breadcrumbs {
      margin-bottom: 14px;
    }

    &--phoneImg {
      display: none;
    }

    &--phoneImg-mob {
      margin-top: 40px;
      position: static;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: -50px;
    }

    &--descriptinBlock {
      h2 {
        font-size: 24px;
        line-height: 48px;
      }

      p {
        font-size: 15px;
        line-height: 22px;
        width: auto;
      }
    }
  }
}
