@import '../../../../styles/variables';

.start-screen {
  flex-direction: column;
  color: $color-text-white;
  background: #0001;
  background: url('../../../../assets/img/main.jpg') top no-repeat opacity 0;
  background-size: cover;
  padding-bottom: 30px !important;
  position: relative;
  height: 93vh;

  &::before {
    z-index: 0;
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(
      90deg,
      #eaeae7 15%,
      transparent 25%,
      transparent 100%
    );
  }

  .container {
    position: relative;
    z-index: 1;
    padding-bottom: 160px;
  }

  &--texts {
    text-align: left;
    color: $main-text;
  }

  &--btns {
    display: flex;
    // margin-top: 100px;
    width: 100%;
    justify-content: space-between;
    max-width: 600px;
  }

  &--btn {
    max-width: 280px;
    width: 100%;
    &:first-child {
      margin-right: 20px;
    }
  }

  &--btn-o {
    &:hover {
      path {
        fill: #fff;
      }
    }
  }

  &--btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transform: translateY(-1px);
      margin-right: 12px;
    }
  }

  &__header {
    max-width: 637px;

    h1 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
    }
  }

  &__slogan {
    z-index: 1;
    display: flex;
    margin-top: 20px;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1px;
    //-webkit-text-stroke: .2px blueviolet;
  }

  &__logos {
    display: flex;
    align-items: flex-end;

    a {
      display: flex;
    }

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-container:last-child {
      padding-left: 13px;
    }

    canvas {
      width: 120px !important;
      height: 120px !important;
      margin-top: 2px;
      border-radius: 8px;
    }
  }
}

.mainScreenSection {
  position: relative;
  color: #fff;
  background: url('../../../../assets/img/main-section-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  h2 {
    margin: 0;
    max-width: 610px;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 40px;
  }

  .container {
    position: relative;
    display: flex;
    padding-top: 100px;
    padding-bottom: 100px;
    z-index: 2;
  }

  p {
    position: relative;
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 62px;

    &:after {
      position: absolute;
      content: '';
      display: inline-block;
      top: 60px;
      left: 30px;
      height: 2px;
      width: 50px;
      background: #fff;
    }
  }

  &--subtitle {
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 20px;
  }

  &--block {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &:last-child {
      align-items: flex-end;

      img {
        width: 302px;
        transform: translateY(-20px);
      }
    }
  }

  &--qrs {
    display: inline-flex;
  }

  &--qr {
    display: inline-flex;
    flex-direction: column;

    &:first-child {
      margin-right: 20px;
    }

    canvas {
      width: 220px !important;
      height: 220px !important;
      margin-top: 2px;
      border-radius: 30px;
      margin-bottom: 20px;
    }

    a {
      margin-right: 20px;
    }
  }
}

@media #{$tablet} {
  .start-screen {
    height: 700px;
    padding-bottom: 20px !important;
    &--btns {
      // margin-top: 50px;
    }

    &__slogan {
      z-index: 1;
      padding-top: 20px;
      font-size: 17px;
      line-height: 25px;
    }
  }
}

@media #{$mobile} {
  .start-screen {
    background-size: contain;
    background-position: bottom;
    background-color: $color-background-mobile;
    // height: auto;
    .container {
      padding-bottom: 58vw;
    }

    .main-box {
      display: block;
      padding: 0;
    }

    &::before {
      background: linear-gradient(
        90deg,
        #eaeae7 15%,
        transparent 45%,
        transparent 100%
      );
    }
    &__logos {
      canvas {
        display: none;
      }
    }

    &.section-block {
      padding: 0;
    }

    &--btns {
      flex-direction: column;
      max-width: none;
      margin-top: 580px;
      // box-shadow: 5px 5px 17px #000;
      button {
        font-size: 17px;
        line-height: 26px;
        padding: 12px 0;
      }

      .start-screen--btn {
        &:first-child {
          margin-right: 0;
          max-width: none;
          margin-bottom: 20px;
        }
      }
    }

    &--texts {
      margin-top: 20px;
    }

    &__slogan {
      margin-top: 15px;
      padding-top: 0;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: normal;
    }

    &__header {
      h1 {
        font-size: 17px;
        line-height: 21px;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  .mainScreenSection {
    .container {
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 40px;
    }

    &::before {
      opacity: 0.9;
      background: $color-background-violate;
    }

    &--subtitle {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
    }

    &:after {
      background-position: right;
    }

    &--block {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      p {
        font-size: 14px;
        line-height: 21px;
        align-self: flex-start;

        &:after {
          top: 29px;
          left: 22px;
        }
      }

      h2 {
        font-size: 24px;
        line-height: 48px;
        font-weight: 600;
        max-width: none;
      }

      &:last-child {
        align-items: center;
        img {
          transform: translateY(0);
        }
      }
    }

    &--qrs {
      padding-top: 20px;
      margin-bottom: 25px;
    }
    &--qr {
      align-items: center;
      margin: 0;
      &:first-child {
        margin-right: 0;
      }

      a {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
