@import "../../../../styles/variables";

.private-profile {

  &__container {
    overflow-x: hidden;
  }

  &__info {
    @include info-block("right");
  }

  &__header {
    @include header-text("right");

  }
  &__description {
    @include description-text("right");
  }

  &__chunk {
    height: 150px;
    background-color: $color-background-white;
  }
}

@media #{$tablet} {
  .private-profile {
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      order: -1;
    }

    &__header {
    }

    &__description {
    }

    &__phone {
    }
  }
}

