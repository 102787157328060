@import "../../../styles/variables";

.referral-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    flex-basis: 100%;
  }

  &__qrs {
    display: flex;
    width: 800px;
    justify-content: space-between;
  }

  &__logos-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    canvas {
      width: 250px !important;
      height: 250px !important;
    }
  }
}
