@import '../../styles/variables';

.header {
  border-bottom: 1px solid $color-text-light-grey;
  &--block {
    display: flex;
    height: 80px;
    align-items: center;

    a {
      &:first-child {
        display: flex;
      }
    }
  }

  &--login-btn {
    font-family: Poppins;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    padding: 7px 14px;
    border: 1px solid $color-background-violate;
    background-color: $color-background-violate;
    border-radius: 3px;

    &:hover {
      background-color: #fff;
    }

    &.header__navigation__link {
      color: #fff;
    }
  }

  &__navigation {
    display: flex;
    margin-left: auto;

    &.mobile {
      z-index: 999999;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100vw;
      background-color: $color-background-white;
      top: 59px;
      left: 0;
      padding-bottom: 20px;
      box-shadow: -1px 3px 6px 0px rgba(0, 0, 0, 0.1);

      .header__navigation__item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 60px;
        padding: 0;
        padding-top: 33px;
      }
    }

    &__item {
      padding: 0 15px;
      font-size: 13px;
      font-weight: 300;
      line-height: 21px;
      letter-spacing: 1px;

      &:last-child {
        padding-left: 25px;
      }
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      color: $color-text-grey;
      letter-spacing: 0.1px;
    }

    &__link:hover,
    .active-link {
      background-color: #fff;
      color: $color-background-violate;
    }

    &__item:last-child {
      padding-right: 0;
    }
  }
}

@media #{$tablet} {
  .header {
    height: 60px;
    display: flex;
    align-items: center;

    &--block {
      height: inherit;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        img {
          width: 143px;
        }
      }
    }

    &__container {
      height: 60px;
      padding: 0 21px;
    }

    &__navigation {
      display: none;

      &__item {
        font-size: $text-medium;
        line-height: 27px;
        font-weight: 400;
      }

      &__link {
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 1px;
        font-family: 'KohinoorBangla';
        color: $color-text-grey3;
      }
    }
  }
}
