@import "../../../styles/variables";

.documents-page {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;

  &__header {
    @include header-text("left")
  }

  ul {
    margin-left: 50px;
  }

  li {
    list-style: circle;
    text-decoration: underline;
  }

  &__list-item {
    padding: 10px;
  }
}

@media #{$tablet} {
  .documents-page {
    padding: 0 0 20px 0;
  }
}
