@import '../../styles/variables';

.funcItem {
  max-width: 404px;
  margin-bottom: 83px;
  a {
    display: flex;
    color: $main-text;
  }

  &--img {
    flex-shrink: 0;
    margin-right: 20px;
    width: 50px;
    height: 50px;
  }

  &--title {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
  }

  &--subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: $color-text-dark-grey;
  }
}

@media #{$tablet} {
  .funcItem {
    margin-bottom: 26px;

    &--title {
      font-size: 17px;
      line-height: 26px;
    }
  }
}
