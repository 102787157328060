@import "../../../styles/variables";

.payment-page {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 350px;
  box-shadow: 0 0 16px rgba(175, 144, 255, 0.24);
  border-radius: 16px;
  padding: 30px 37px;

  &__item {
    margin: 0 0 25px 0;
  }

}

@media #{$tablet} {
  .payment-page {
    margin: 0 auto;
  }
}
