@import "./src/styles/variables";

@mixin input {
  border: none;
  border-bottom: 1px solid $color-text-light-grey;
  width: 100%;
  font-size: $text-medium-sm;

  &::placeholder {
    font-size: $text-standard;
    opacity: 0.5;
  }

  &:focus, &:active {
    outline: none;
    border-bottom: 1px solid $color-text-light-grey;
  }
}

.ui-kit-input {
  position: relative;

  input {
    @include input;
  }

  &__hint-wrapper {
    padding: 5px 0 0 0;
    font-size: $text-standard;
  }


  .rc-input-suffix {
    position: absolute;
    right: 0;
    top: 50%;
    background-color: $color-background-white;
    cursor: pointer;
  }

  label {
    cursor: pointer;
    color: $color-text-grey;
    font-weight: 800;
    opacity: 0.8;
    font-size: $text-standard;
  }

  &:focus-within {
    label {
      color: $color-text-violet;
    }
  }
}
