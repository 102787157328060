@import '../../../styles/variables.scss';

.securePage {
  padding-top: 40px;
  color: $color-text-dark-grey;
  .container {
    max-width: 710px;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 30px;
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 1px;
  }

  a {
    color: $color-text-violet;
  }

  li {
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 30px;
  }
}

@media #{$tablet} {
  .securePage {
    .container {
      img {
        width: 100%;
      }
    }

    h1,
    h2,
    h3 {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
