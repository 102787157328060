@import "../../styles/variables";

.sticker-requirements {
  align-self: flex-start;
  &__content {
    padding-top: 30px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin-bottom: 50px;
  }

  &__title {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__description {
    font-family: 'Kohinoor Bangla';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }

  &__button {
    display: flex;
    align-self: flex-start;
    background: #af90ff;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;
    margin-top: 20px;
    padding: 15px 30px;
    a {
      color: white;
      letter-spacing: 1.8px;
    }
  }
}

@media #{$tablet} {
  .sticker-requirements {
    &__block {
      max-width: none;
    }
  }
}
