@import "../../../../styles/variables";

.hiring {
  padding-top: 150px;

  &__container {
  }

  &__info {
    @include info-block("right");
  }

  &__header {
    @include header-text("right");

  }
  &__description {
    @include description-text("right");
    margin-left: 100px;
  }

  &__phone {
    position: relative;
  }

  &__lens {
    position: absolute;
    left: 50%;
    top: 80px;
  }

  &__chunk {
    height: 150px;
    background-color: $color-background-white;
  }
}

@media #{$tablet} {
  .hiring {
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__info {
      order: -1;
    }

    &__header {
    }

    &__description {
      margin-left: 0;
    }

    &__lens {
      top: auto;
      bottom: 50px;
      left: -20px;
    }
  }
}
