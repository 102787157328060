@import "../../../styles/variables";

.unsubscribe-page {
  display: flex;
  flex: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__button {
    @include button;
    cursor: pointer;
  }

  &__link, &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;

    a {
      text-decoration: underline;
    }
  }
}
