.animated-sticker-editor {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0002;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 15px;
    background: #fff;
    min-width: 300px;
    max-width: 550px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    img {
      width: 300px;
      height: 300px;
    }
  }

  &--toolbar {
    margin-top: 30px;
    width: 100%;
  }

  &--range {
    margin-bottom: 20px;
    width: 100%;
  }

  &--canvas {
    background: #0003;
  }

  &--done-btn {
    button {
      height: 40px;
      width: 120px;
    }
  }

  &--close {
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    img {
      height: 50%;
    }
  }
}


.emoji-matcher {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-height: 150px;
  overflow: auto;
  margin-bottom: 20px;
  &--btn {
    font-size: 28px;
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;
    line-height: 30px;
    flex-shrink: 0;
    border: none;
    background: #fff;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: #0001;
    }

    &-active {
      background: #AF90FF !important;
    }
  }
}