@import '../../styles/variables.scss';

.blogItem {
  color: $color-text-dark-grey;

  &--bg {
    width: 100%;
  }

  &--textBlock {
    padding-top: 50px;
    padding-bottom: 50px;
    max-width: 710px;
    margin-left: auto;
    margin-right: auto;
  }

  &--title {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 10px;
  }

  &--date {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #b2b2b2;
    margin-bottom: 50px;
  }

  &--text {
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: 1px;
    p {
      margin-bottom: 40px;
    }
  }
}

@media #{$tablet} {
  .blogItem {
    &--bg {
      margin-left: -20px;
      margin-right: -20px;
      width: auto;
      height: 30vw;
    }

    &--title {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    &--date {
      margin-bottom: 20px;
    }
  }
}
