@import "../../../styles/variables";

.donate-page {
  display: flex;
  justify-content: center;

  &__header {
    display: inline-flex;
    justify-content: center;
    font-size: $text-big-sm;
    color: $color-text-dark-grey;

    h3 {
      margin: 0 0 10px 0;
    }
  }

  &__card-payments {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }

  &__card-payments-systems-button {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__qr-button {
    height: 20px;
    width: 220px;
    font-size: $text-small;
    background-color: transparent;
    box-shadow: none;
    color: $color-text-violet;
  }

  &__card-payments-systems-icons-container {
    display: inline-block;
    margin-left: 3px;
    margin-top: 3px;
    img {
      margin: 0 2px;
    }
  }

  &__wallets-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 730px;
    padding: 0 20px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__payment-divider {
    display: flex;
    justify-content: center;
    position: relative;
    color: $color-text-grey;
    padding: 20px 0;

    p {
      position: absolute;
      padding: 0 10px;
      background-color: $color-background-white;
    }

    hr {
      width: 100%;
      height: 1px;
      border: none;
      background-color: $color-text-grey;
    }
  }

  &__wallet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid $color-text-grey;

    &:last-child {
      border-bottom: none;
    }
  }

  &__wallet-name {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 0 0 auto;
    font-weight: 600;

    img {
      transform: scale(1.3);
    }
  }

  &__wallet-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid $color-text-grey;
    padding: 2px 5px;
    width: 400px;

    &:hover {
      background-color: $color-background-green;
    }
  }

  &__wallet-address-icon {
    transform: scale(.7);
    margin-left: 5px;
  }

  &__footer {
    padding: 20px;
  }
}

@media #{$tablet} {
  .donate-page {
    &__qr-button {
      display: none;
    }
  }
}

@media #{$mobile} {
  .donate-page {
    font-size: $text-small;

    &__wallets-container {
      width: auto;
      padding: 0 20px;
    }


    &__wallet {
      flex-direction: column;
    }

    &__wallet-address {
      width: auto;
    }
  }
}
