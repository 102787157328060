@import '../../../../styles/variables';

.pets-container {
  overflow: visible;
  .channels {
    &__container {
      padding-top: 150px;
      background-color: $color-background-grass-green;
      overflow-x: hidden;
      overflow: visible;
      // flex-direction: row-reverse;
    }

    &__phone {
      transform: translateY(80px);
    }
  }
}

@media #{$tablet} {
  .pets-container {
    .channels {
      &__container {
        padding-top: 84px;
        flex-direction: column;
      }
    }
  }
}
