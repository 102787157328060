@import "./sizes";

//font colors

.b {
  background-color: #4A4A4A;
}

$main-text: #2A2E3C;

$color-text-white: #ffff;
$color-text-grey: #9B9B9B;
$color-text-grey2: #6a6d77;
$color-text-grey3: #919EAB;
$color-text-grey-opacity: rgba(255, 255, 255, 0.6);
$color-text-dark-grey: #4A4A4A;
$color-text-violet: #AF90FF;
$color-text-light-grey: #E5E5E5;
$color-text-green: #62DD90;
$color-text-orange: #FFA500;
$color-text-red: #F1522F;

//bg colors

$color-background-green: #62DD90;
$color-background-mobile: #EBEBE8;
$color-background-grass-green: #87BB4F;
$color-background-grey: #9B9B9B;
$color-background-white-grey: #F4F6FA;
$color-background-yellow: #FFD328; 
$color-background-yellow-2: #EFD753; 
$color-background-white: #ffff;
$color-background-violate: #AF90FF;
$color-background-gold: #E8B70A;
$color-background-aqua: #06B2E9;
$color-background-aquamarine: #06B2E9;
$color-background-red: #F1522F;
$color-background-blue: #5364FF;

@mixin button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 64px;
  outline: none;
  border: none;
  background-color: $color-background-violate;
  color: $color-text-white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.15);
  border-radius: 100px;
  font-weight: 600;
  font-size: $text-medium;
  cursor: pointer;

  &:disabled{
    background-color: $color-background-grey;
    cursor: not-allowed;
  }
}



@mixin header-text($position: "center") {
  font-weight: 700;
  font-size: $text-big;
  line-height: 90px;
  letter-spacing: 1px;
  color: $color-text-dark-grey;
  text-align: $position;

  @media #{$tablet} {
    font-weight: bold;
    line-height: 55px;
    text-align: center;
    letter-spacing: 1px;
    font-size: $text-big-mobile;
  }
}

@mixin description-text($position) {
  font-weight: 500;
  font-size: $text-medium-sm;
  line-height: 27px;
  letter-spacing: 1px;
  color: $color-text-grey;
  text-align: $position;
  padding-top: 20px;

  @media #{$tablet} {
    font-weight: 500;
    font-size: $text-medium-sm-mobile;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;
  }
}

@mixin secondary-header-text {
  font-weight: bold;
  font-size: $text-big-sm;
  line-height: 60px;
  text-align: center;
  letter-spacing: 1px;

  @media #{$tablet} {
    font-size: $text-big-md-mobile;
    line-height: 45px;
  }
}

@mixin secondary-description-text {
  font-style: normal;
  font-weight: 500;
  font-size: $text-medium-sm-mobile;
  line-height: 27px;
  text-align: center;
  letter-spacing: 1px;

  @media #{$tablet} {
    font-size: $text-medium-sm-mobile;
    line-height: 25px;
  }
}


@mixin info-block($position) {
  flex: 0 0 auto;
  max-width: 60%;
  padding: 80px 0 0;

  @if $position == "left" {
    margin-right: auto;
    text-align: left;
  }

  @if $position == "right" {
    margin-left: auto;
    text-align: right;
  }

  @media #{$tablet} {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0;
    align-items: center;
    padding: 30px 0 50px;
  }
}

