@import "../../../../styles/variables";

.profile-menu {
  display: flex;
  justify-content: space-between;
  flex: 1 0 auto;
  margin: 10px 0;

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__user-profile {
    display: none;
  }

  &__item {
    padding: 10px 0;

    &-active-link {
      color: $color-text-violet !important;
      text-decoration: underline !important;
    }


    a {
      color: $color-text-dark-grey;
      padding: 5px 0 5px 10px;
      text-decoration: none;

      &:hover {
        color: $color-text-violet;
        text-decoration: underline;
      }
    }
  }

  &__burger-menu {
  }

}

@media #{$tablet} {
  .profile-menu {

    &__list {
      display: none;

      &.mobile {
        display: block;
      }
    }

    &__user-profile {
      display: block;
    }

  }
}
