.prepared-sticker {
  img {
    width: 100%;
    object-fit: contain;
  }

  video {
    width: 100px;
  }

  *:host {
    width: 100%;
    height: 100%;
  }
}