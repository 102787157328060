@import "../../../styles/variables";

.video-examples {
  //margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__header {
    @include header-text
  }
}

@media #{$tablet} {
  .video-examples {
    justify-content: space-around;
  }
}

@media #{$mobile} {
  .video-examples {
  }
}
