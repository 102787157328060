@import '../../../styles/variables.scss';
html {
	scroll-behavior: smooth;
}
.subscription-section {
  position: relative;

  &--goToExamplesBtnBlock {
    width: 100%;
    display: none;
    padding: 10px;
  }

  &--goToExamplesBtn {
    width: 100%;
    font-size: 16px;
    line-height: 1;
  }

  &--hint {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #0009;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
      font-weight: 500;
      font-size: 22px;
      line-height: 33px;
      color: #fff;
    }
  }
  &--videos {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: $color-background-violate;
    color: #fff;

    .container {
      max-width: 924px;
      padding: 0;
    }

    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      margin: 0;
      margin-bottom: 10px;
    }
  }

  &--videos-block {
    display: flex;
    align-items: center;
  }
  &--videos-avatar {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: 10px;
  }

  &--main-img {
    width: 100%;
    margin-bottom: 20px;
    height: 180px;
    object-fit: cover;
    object-position: top;
    cursor: pointer;
  }

  &--list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 50px;

    li {
      width: 294px;
      div {
        font-weight: 600;
        color: #fff;
        font-size: 17px;
        line-height: 26px;
      }

      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 1px;
        color: #fff;
      }
    }
  }
}

@media #{$tablet} {
  .subscription-section {
    &--goToExamplesBtnBlock {
      display: block;
    }
    
    &--videos {
      padding-top: 40px;
    }
    &--header {
      padding: 0px 40px;
      h2 {
        font-size: 18px;
        line-height: 27px;
      }

      a {
        position: relative;
        top: -2px;
        font-size: 14px;
        line-height: 21px;
      }
    }

    &--list {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 40px;
      grid-row-gap: 45px;
      li {
        width: 100%;
      }
    }
  }
}
